import React from "react"
import MainLayout from "../../components/MainLayout"
import SEO from "../../components/seo"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import ImagePicker from "../../components/ImagePicker"
import ProductDescription from "../../components/products/ProductDescription"
import IndexSection3 from "../../components/IndexSection3"

import Image1 from "../../images/m-series/timberline-m-series-24-orbital-16g-16g-tanks.jpg"
import Image2 from "../../images/TIMBERLINE-M-SERIES-28-O-front-side-WEB-THUMB.jpg"
import Image3 from "../../images/TIMBERLINE-M-SERIES-28-O-front-side-back-WEB-THUMB.jpg"
import Image4 from "../../images/TIMBERLINE-M-SERIES-28-O-front-side-back-twist-WEB-THUMB.jpg"

import Image1Large from "../../images/m-series/timberline-m-series-24-orbital-16g-16g-tanks.jpg"
import Image2Large from "../../images/TIMBERLINE-M-SERIES-28-O-front-side-WEB-Large.jpg"
import Image3Large from "../../images/TIMBERLINE-M-SERIES-28-O-front-side-back-WEB-Large.jpg"
import Image4Large from "../../images/TIMBERLINE-M-SERIES-28-O-front-side-back-twist-WEB-Large.jpg"

const ProductLayout = styled.div`
  #products-layout {
    padding-top: 150px;
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-columns: 50% 50%;
    /* align-items: baseline; */

    @media (max-width: 1130px) {
      grid-template-columns: 100%;
      padding-top: 100px;
    }
  }
`

const M28OSPage = () => {
  return (
    <>
      <SEO title="M28 Floor Scrubber | Timberline Cleaning Equipment" />
      <Helmet>
        <title>M28 Floor Scrubber | Timberline Cleaning Equipment</title>
        <description>
          The M28 Orbital Scrubber reduces the large labor cost associated with
          traditional floor stripping methods. The 24-inch (61 cm) rectangular
          style pad is installed and removed without tools to allow ease when
          switching between different pads, depending upon the application’s
          needs. The squeegee blades can be rotated or flipped without tools.
          Simplify daily, monthly, and yearly cleaning and restoration processes
          with this chemical-free solution to floor finish removal.
        </description>
      </Helmet>
      <MainLayout>
        <ProductLayout>
          <div id="products-layout">
            <ImagePicker
              image1={Image1}
              image2={Image2}
              image3={Image3}
              image4={Image4}
              image1Large={Image1Large}
              image2Large={Image2Large}
              image3Large={Image3Large}
              image4Large={Image4Large}
              mobileLink="#demo-section"
              mobileTitle="M28 Orbital"
            />
            <ProductDescription
              productTitle="M28 Orbital"
              subHeading="Walk-Behind Medium Floor Scrubbers"
              modelNum="M28R"
              productDescription="The M28 Orbital Scrubber reduces the large labor cost associated with traditional floor stripping methods.  The 28-inch (71 cm) rectangular style pad is installed and removed without tools to allow ease when switching between different pads, depending upon the application’s needs.  The squeegee blades can be rotated or flipped without tools.  Simplify daily, monthly, and yearly cleaning and restoration processes with this chemical-free solution to floor finish removal. "
              link="#demo-section"
              list1="Constructed robustly — steel frame construction and durable components "
              list2="Simplify maintenance with easy access to the battery and recovery tank"
              list3="Traction drive speed controlled with easy to use Drive Speed Control thumb dial"
              list4="Easily operate and maneuver with push button forward and toggle reverse"
              list5="Clean any hour of the day with approved sound levels < 67 dBA"
              list6="Increase productivity with over 2.5 hours of run time between dump and refill"
              list7="Off-the-shelf toggles, switches, gauges, and mechanical levers to allow easy operator training and potential repairs"
              brochure="../../timberline-walk-behind-sm.pdf"
              tech="../../tl-m-series-tech-specs-v21263.pdf"
              parts="https://www.dropbox.com/s/ljt4kuixorf5z6f/t-m-series-p-en.pdf?dl=0"
              operator="https://www.dropbox.com/s/55f8a8woffv81p8/t-m-series-op-en.pdf?dl=0"
              hood="../../timberline-underthehood.pdf"
              pm="../../M-Series-PM.pdf"
              mtLink="https://www.dropbox.com/s/evrcuco63ko0478/daily-weekly-maintenance-3rd-v21306.pdf?dl=0"
              />
          </div>
          <div id="demo-section"></div>
          <IndexSection3 title="Request a Free Demo at your Facility" />
        </ProductLayout>
      </MainLayout>
    </>
  )
}

export default M28OSPage
